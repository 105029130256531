import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Container from "../components/container"
import SEO from "../components/seo"

export default ({ data }) => {
  return (
    <Layout>
      <SEO
        title="TASE Industries"
        keywords={[`tase`, `industries`, `development`]}
      />
      <Container>
        <div style={{ color: `teal` }}>
          <h1>About {data.site.siteMetadata.title}</h1>
          <p>We build pretty cool things</p>
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
